import "core-js/stable"
import "regenerator-runtime/runtime"

import postClicks from 'clicks/components/PostClicks'

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll("post-clicks")).forEach((el) => {
    if (el instanceof HTMLElement) {
      postClicks(el)
    }
  })
})

