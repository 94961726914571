import axios from "axios"
import * as Highcharts from 'highcharts';

interface callbackFilter { (element: any): boolean }

const dropWhile = (arr: Array<any>, func: callbackFilter) => {
  while (arr.length > 0 && func(arr[0])) arr = arr.slice(1);
  return arr;
}
axios.defaults.withCredentials = false

type SomeTuple = [number, number];
type ListOfTuples = SomeTuple[];

const initialFetch = async (url: string): Promise<ListOfTuples> => {
  const r = await axios.get(url, { withCredentials: true, })
  if (!r.data[0]) return []
  return dropWhile(r.data[0].data, ([_, c]) => c == 0)
}

const chart = (data: ListOfTuples, element: HTMLElement): void => {
  const options: Highcharts.Options = {
    title: { text: null },
    subtitle: { text: null },
    exporting: {},
    xAxis: {
      type: 'datetime',
      labels: {
        style: { fontSize: '8px' }
      }
    },
    yAxis: {
      visible: false,
      lineWidth: 0,
      minorGridLineWidth: 0,
      gridLineColor: "#ffffff",
    },
    series: [
      { data, name: "Klicks je Monat" }
    ],
    legend: { enabled: false },
    credits: { enabled: false },
    pane: { background: [] },
    responsive: { rules: [] },
  }
  Highcharts.chart(element, options)
}

export default (el: HTMLElement): void => {
  const url = el.dataset.url
  let sum = 0
  if (!(typeof url == 'string')) return

  initialFetch(url).then((data) => {
    const newChild =  document.createElement('div')
    newChild.style.width = '150px'
    newChild.style.height = '100px'
    newChild.classList.add("chart")
    sum = data.map(([_, c]) => c).reduce((sum, data) => sum + data, 0)
    const sumChild =  document.createTextNode(sum.toString())


    el.appendChild(sumChild)
    el.appendChild(newChild)
    chart(data, newChild)
  })
}







